<template>
  <b-row>
    <b-col
      md="12"
    >
      <b-card
        style="display:flex;justify-content: space-between;justify-items: center;"
      >
        <h2
          style="width:max-content;"
        >
          Liste des pièces
        </h2>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="float-right mb-1"
          onclick="history.back()"
          style="width:max-content;"
        >
          <feather-icon icon="ArrowLeftCircleIcon" />
          Retour
        </b-button>
      </b-card>
    </b-col>
    <b-row v-if="pieces.length">
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <b-card no-body>
              <b-card-body>
                <b-list-group>
                  <b-list-group-item
                    class="d-flex justify-content-between align-items-center"
                    active
                  >
                    <span>Informations de l'utilisateur</span>
                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Nom de l'interessé</span>
                    {{ pieces[0].user.lastname }}
                  </b-list-group-item>

                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Numéro de téléphone</span>
                    {{ pieces[0].user.telephone }}

                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Adresse e-mail</span>
                    {{ pieces[0].user.email }}
                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Rôle</span>
                    {{ pieces[0].user.role }}

                  </b-list-group-item>
                  <b-list-group-item class="d-flex justify-content-between align-items-center">
                    <span>Status du compte</span>
                    {{ pieces[0].user.status }}

                  </b-list-group-item>
                </b-list-group>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="12">
            <b-card>
              <b-table
                striped
                hover
                responsive
                small
                :busy="loader"
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="pieces"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="d-flex justify-content-center mb-3">
                    <b-spinner
                      style="width: 2rem; height: 2rem;"
                      variant="primary"
                      label="Large Spinner"
                      type="grow"
                      class="mt-2"
                    />
                    <span class="sr-only">Chargement en cours...</span>
                  </div>
                </template>
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="`${resolveUserStatusVariant(data.item.status)}`"
                    class="text-capitalize"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      v-b-modal.modal-prevent-image-closing
                      @click="getImage(data.item)"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        class="text-primary"
                      />
                      <span class="align-middle ml-50 text-primary">Voir</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-b-modal.validation
                      @click="getItem('Validé',data.item)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="text-success"
                      />
                      <span class="align-middle ml-50 text-success">Valider</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-b-modal.rejet
                      @click="getItem('Rejeté',data.item)"
                    >
                      <feather-icon
                        icon="AlertTriangleIcon"
                        class="text-danger"
                      />
                      <span class="align-middle ml-50 text-danger">Rejeter</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template></b-table>

              <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

                <!-- page length -->
                <b-form-group
                  label="Par Page"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    inline
                    :options="pageOptions"
                  />
                </b-form-group>

                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="pieces.length"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 pagination-center"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </b-card-body>

            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-modal
        id="modal-prevent-image-closing"
        ref="my-modal"
        button-size="sm"
        title="Pièce jointe"
        ok-only
        ok-title="Fermer"
      >
        <iframe
          :src="imageUrl"
          style="width: 100%; height: 600px; border: none;"
        >
          Oops! an error has occurred.
        </iframe>

      </b-modal>
      <!-- form modal -->
      <b-modal
        id="validation"
        ref="my-modal"
        cancel-title="Non"
        ok-title="Oui"
        title="Validation de pièce"
        modal-class="modal-success"
        ok-variant="success"
        button-size="sm"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @ok="handleValidateOk"
      >
        <form @submit.prevent="handleValidateSubmitModal">
          <h6>Etes-vous sûr de vouloir valider cette piece?</h6>

        </form>
      </b-modal>
      <b-modal
        id="rejet"
        ref="my-modal"
        cancel-title="Non"
        ok-title="Oui"
        title="Rejet de pièce"
        modal-class="modal-danger"
        ok-variant="danger"
        button-size="sm"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        @ok="handleRejeterOk"
      >
        <b-form @submit.prevent="handleRejeterSubmitModal">
          <label for="textarea-default">Commentaire</label>
          <b-form-textarea
            id="textarea-default"
            v-model="form.commentaire"
            placeholder="Commentaire"
            rows="3"
          />
        </b-form>
      </b-modal>
    </b-row>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BDropdown, BDropdownItem,
  BBadge, BSpinner, BModal, VBModal, BFormTextarea, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import {
  required, email, confirmed, password, image, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import usePieces from '@/services/media/mediaService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BModal,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BFormSelect,
    BPagination,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BBadge,

    BSpinner,

  },
  directives: {
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
      password,
      email,
      confirmed,
      image,
      ext,
      mimes,
    }
  },
  setup() {
    const {
      imageUrl, handleImageSelected,
    } = useImageUpload()
    const {
      pieces, getPiecesByUserId, pieceSuccess, loader, validationPiece,
    } = usePieces()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'document.name', label: 'Document', sortable: true },
      { key: 'numero_piece', label: 'N° Piece', sortable: true },
      { key: 'date_expiration', label: 'Date Expiration', sortable: true },
      { key: 'status', label: 'Status Compte', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      status: '',
      commentaire: '',
      id: null,
    })
    const clearPiece = () => {
      form.status = ''
      form.commentaire = ''
      form.id = null
    }

    /* eslint-disable global-require */
    const items = pieces
    onMounted(async () => {
      await getPiecesByUserId()
      console.log(pieces)
      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const getImage = item => {
      imageUrl.value = item.image_url
      console.clear()
    }
    const changerStatus = async () => {
      await validationPiece({ ...form })
      if (pieceSuccess.value === true) {
        clearPiece()
        console.clear()
      }
    }
    // Obtenir une piece
    const getItem = async (state, item) => {
      form.id = item.id
      form.status = state
    }
    const resolveUserStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }

    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      items,
      config,
      fields,
      handleImageSelected,
      onFiltered,
      pieces,
      form,
      changerStatus,
      imageUrl,
      getItem,
      loader,
      clearPiece,
      getImage,
      pieceSuccess,
      resolveUserStatusVariant,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleRejeterOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleRejeterSubmitModal()
    },
    handleRejeterSubmitModal() {
      // Exit when the form isn't valid

      this.changerStatus()
      // Hide the modal manually
      if (this.pieceSuccess) {
        this.$nextTick(() => {
          this.$refs['my-modal'].toggle('#toggle-btn')
        })
      }
    },
    handleValidateOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleValidateSubmitModal()
    },
    handleValidateSubmitModal() {
      // Exit when the form isn't valid

      this.changerStatus()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

  },

}
</script>
