import { ref } from '@vue/composition-api'
import router from '@/router'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/services/notification/useAlert'

export default function usePieces() {
  const { errorToast, successToast } = useAlertNotification()

  const piece = ref([])
  const loader = ref(false)
  const pieceProcess = ref(false)
  const pieceSuccess = ref(false)
  const pieces = ref([])
  const errors = ref('')

  // Liste des pieces
  const getPieces = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-media-by-user')
      if (response.data.success === true) {
        loader.value = false
        pieces.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getPiecesByUserId = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-media-by-user-id/${router.currentRoute.params.id}`)
      if (response.data.success === true) {
        loader.value = false
        pieces.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  // Obtenir une piece
  const getPiece = async id => {
    const response = await axiosClient.get(`/get-media-by-id/${id}`)
    piece.value = response.data.data
  }
  const deletePiece = async id => {
    try {
      errors.value = ''
      pieceProcess.value = true
      const response = await axiosClient.delete(`/supprimer-piece/${id}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        pieceSuccess.value = true
        pieceProcess.value = false
        await getPieces()
      }
    } catch (error) {
      pieceProcess.value = false
      pieceSuccess.value = false
      loader.value = false
      errors.value = ''
      errorToast('Oups! Erreur', error.response.data.message)
    }
  }
  const validationPiece = async data => {
    try {
      errors.value = ''
      pieceProcess.value = true
      const response = await axiosClient.post('/changer-status-piece', data)
      if (response.data.success === true) {
        successToast('Changement de status', response.data.message)
        pieceSuccess.value = true
        pieceProcess.value = false
        await getPiecesByUserId()
      }
    } catch (error) {
      pieceProcess.value = false
      pieceSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }
  // Ajouter une piece
  const createOrUpdatePiece = async data => {
    try {
      errors.value = ''
      pieceProcess.value = true
      const response = await axiosClient.post('/store-or-update-piece', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        pieceSuccess.value = true
        pieceProcess.value = false
        await getPieces()
      }
    } catch (error) {
      pieceProcess.value = false
      pieceSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      const createUserErrors = error.response.data.errors
      Object.keys(createUserErrors).forEach(key => {
        errorToast('Oups! Erreur', createUserErrors[key][0])
      })
    }
  }
  const changerStatusMedia = async data => {
    try {
      errors.value = ''
      pieceProcess.value = true
      const response = await axiosClient.post('/changer-status-piece', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        pieceSuccess.value = true
        pieceProcess.value = false
        await getPieces()
      }
    } catch (error) {
      pieceProcess.value = false
      pieceSuccess.value = false
      loader.value = false
      errors.value = ''
      if (error.response.status === 403) {
        errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
      } else {
        errors.value = error.response.data.errors
        const createUserErrors = error.response.data.errors
        Object.keys(createUserErrors).forEach(key => {
          errorToast('Oups! Erreur', createUserErrors[key][0])
        })
      }
    }
  }

  return {
    errors,
    piece,
    pieces,
    getPieces,
    pieceProcess,
    createOrUpdatePiece,
    getPiece,
    changerStatusMedia,
    getPiecesByUserId,
    loader,
    validationPiece,
    deletePiece,
    pieceSuccess,
  }
}
